import { Component, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Observable } from 'rxjs';
import { ComponentMenu } from '../../interfaces/interfaces';
import {Storage} from '@ionic/storage-angular';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  username = '';

  components: Observable<ComponentMenu[]>;

  constructor(private dataService: DataService, private storage: Storage) { }

  ngOnInit() {
    this.components = this.dataService.getMenuOptions();
    //this.getUsername();
  }

  async getUsername() {
    this.username = await this.storage.get('user') || null;
  }
}
