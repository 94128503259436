import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage-angular';
import { environment } from 'src/environments/environment';
import {User, Debt, Sesion} from '../interfaces/interfaces';
import { NavController } from '@ionic/angular';

const URL = environment.url;

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  token: string = null;
  user: User;
  debts: Debt[] = [];

  constructor( private http: HttpClient, private storage: Storage, private navCtrl: NavController) {
    this.storage.create();
  }


  login(userId: number, userPass: string) {

    const data  = {userId, userPass};
    const response  = [false, false];

    return new Promise(resolve => {

      const headers = new HttpHeaders({
        'x-token': ''
      });

      this.http.post<Sesion>( `${URL}/login`, data, { headers })
      .subscribe ( resp => {

        if(resp.token == null) {
          this.clearToken();
        } else {
          this.guardarToken(resp.token, resp.user);
          response[0] = true;
        }

        resolve(response);

      }, error => {
        this.clearToken();
        resolve(response);
      });

    });


  }

  async validateToken(): Promise<boolean> {

    await this.loadToken();

    if( ! this.token ) {
      this.navCtrl.navigateRoot( '/login', {animated: true});
      return Promise.resolve(false);
    }

    return new Promise<boolean>( resolve => {

      const headers = new HttpHeaders({
        'x-token': this.token
      });
      const data  = {};

      this.http.post<Sesion>(`${URL}/login`, data, { headers })
      .subscribe( resp => {
           if((resp.isEmptyPass)) {
             this.clearToken();
             this.navCtrl.navigateRoot( '/login' , {animated: true});
           }
          resolve(true);
      }, error => {
        this.clearToken();
        this.navCtrl.navigateRoot( '/login' , {animated: true});
        resolve(false);
      });
    });

  }

  async guardarToken(token: string, user: string) {
    this.token = token;
    await this.storage.set('token', token);
    await this.storage.set('user', user);
  }

  async loadToken() {
    this.token = await this.storage.get('token') || null;
  }

  async clearToken() {
    this.token = null;
    this.storage.clear();
  }


  async updatePassword(userId: number, userPass: string) {

    const data  = {userId, userPass};

    return new Promise(resolve => {

      this.http.post( `${URL}/login/updatePassword`, data )
      .subscribe ( resp => { resolve(true); },
                   error => {resolve(false); });
    });


  }



}
