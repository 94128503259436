import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {UsuarioGuard} from './guards/usuario.guard';

const routes: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)},
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'tithe-payment',
    loadChildren: () => import('./pages/tithe-payment/tithe-payment.module').then(m => m.TithePaymentPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'debt-payment',
    loadChildren: () => import('./pages/debt-payment/debt-payment.module').then(m => m.DebtPaymentPageModule),
    canLoad: [UsuarioGuard]
  },
  {
    path: 'guest',
    loadChildren: () => import('./pages/guest/guest.module').then( m => m.GuestPageModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
